@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code,
pre {
  font-family: source-code-pro, Menlo, Monaco, 'Fira Code', Consolas, 'Courier New', monospace;
}
input,
select,
textarea {
  @apply outline-none bg-transparent min-w-0;
}
input.input,
select.input,
textarea.input {
  @apply border border-neutral-300 rounded px-1 py-px;
}
input[type=number].no-spin {
  -moz-appearance: textfield;
}
input[type=number].no-spin::-webkit-outer-spin-button,
input[type=number].no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.button {
  @apply border border-neutral-300 rounded px-2 py-px;
  @apply hover:bg-neutral-300 active:bg-neutral-500;
  @apply cursor-pointer font-medium;
}
.button svg:first-child {
  @apply mr-1;
}
.button svg:last-child {
  @apply ml-1;
}
.button.blue {
  @apply border-blue-500 bg-blue-500 hover:bg-blue-600 active:bg-blue-700;
  @apply text-white;
}
.button.blue:disabled {
  @apply cursor-default border-blue-300 bg-blue-300 text-neutral-50;
}
.button.green {
  @apply border-green-500 bg-green-500 hover:bg-green-600 active:bg-green-700;
  @apply text-white;
}
.button.green:disabled {
  @apply cursor-default border-green-300 bg-green-300 text-neutral-50;
}
.button.red {
  @apply border-red-500 bg-red-500 hover:bg-red-600 active:bg-red-700;
  @apply text-white;
}
.button.red:disabled {
  @apply cursor-default border-red-300 bg-red-300 text-neutral-50;
}
table.table {
  @apply w-full border my-4;
  @apply border border-neutral-300;
}
table.table thead {
  @apply border-b border-neutral-300;
}
table.table thead th {
  @apply border-r last:border-r-0 border-neutral-300 px-2;
}
table.table thead th svg {
  @apply mr-2;
}
table.table tbody tr {
  @apply even:bg-neutral-100 hover:bg-neutral-200;
}
table.table tbody tr td {
  @apply px-2 border-r last:border-r-0 border-neutral-300;
}
table.table tbody tr td.currency {
  @apply border-r-0 w-0 pr-0 text-right;
}
div.input {
  @apply border rounded-md px-2 border-neutral-300;
}
div.input > input {
  @apply min-w-0;
}
ul.tabs {
  @apply flex gap-3;
}
ul.tabs > li.tab {
  @apply border rounded-md py-2 px-3 font-medium;
  @apply cursor-pointer hover:bg-zinc-100 active:bg-zinc-200;
}
ul.tabs > li.tab.active {
  @apply underline underline-offset-2 decoration-blue-500 decoration-4;
}
