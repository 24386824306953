section.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  background-image: url(../assets/wood.jpg);
  background-size: cover;
  background-position-y: center;
  padding-inline: max(0px, (100vw - 768px) / 2);
}
section.container .backdrop_item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: min(768px, 96%);
  max-height: calc(100vh - 4rem);
  width: 100%;
  height: auto;
  object-fit: contain;
}
section.container .content {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16rem;
  margin-bottom: 200px;
}
section.container .content img.title {
  width: 100%;
}
section.container .content .button {
  padding-block: calc(0.3rem + 0.15rem);
  padding-inline: calc(0.75rem + 0.15rem);
  /* border: 2px solid red; */
  border-radius: 0.75rem;
  background-color: #fff100;
  color: #ed2d21;
  font-weight: 700;
  transition: transform 0.05s;
  position: relative;
  transform: scale(var(--btn-scl, 1));
}
section.container .content .button::before {
  content: "";
  position: absolute;
  top: 0.15rem;
  bottom: 0.15rem;
  left: 0.15rem;
  right: 0.15rem;
  border-radius: 0.75rem;
  border: 2px solid red;
  pointer-events: none;
}
section.container .content .button:hover {
  transform: scale(calc(var(--btn-scl, 1) * 1.1));
}
section.container .content .button:active {
  transform: scale(calc(var(--btn-scl, 1) * 0.95));
}
section.container .content .button.big {
  --btn-scl: 2;
}
