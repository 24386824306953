section.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff100;
  display: grid;
  place-items: center;
}
section.container::before {
  content: "";
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  border: 0.5rem solid #ed2d21;
  border-radius: 1rem;
  pointer-events: none;
}
section.container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
}
section.container .content .header {
  width: min(250px, 80vw);
  margin-bottom: 3rem;
  pointer-events: none;
}
section.container .content .ingredient {
  position: relative;
  width: 150px;
  cursor: pointer;
  transition: transform 0.1s;
}
section.container .content .ingredient img {
  height: 150px;
  margin-inline: auto;
}
section.container .content .ingredient:hover {
  transform: scale(1.1);
}
section.container .content img.prize {
  filter: drop-shadow(0px 0px 50px white) drop-shadow(0px 0px 50px white);
}
section.container .content input.input {
  background-color: white;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  border-radius: 0.75rem;
  box-shadow: 2.5px 2.5px 2.5px rgba(0 0 0 / 0.15);
  width: min(350px, 90vw);
}
section.container .content input.input:read-only {
  @apply bg-neutral-100 text-gray-500 cursor-not-allowed;
}
section.container .content .button {
  padding-block: calc(0.3rem + 0.15rem);
  padding-inline: calc(0.75rem + 0.15rem);
  /* border: 2px solid red; */
  border-radius: 0.75rem;
  background-color: #fff100;
  color: #ed2d21;
  font-weight: 700;
  transition: transform 0.05s;
  position: relative;
  transform: scale(var(--btn-scl, 1));
  box-shadow: 2.5px 2.5px 2.5px rgba(0 0 0 / 0.15);
}
section.container .content .button::before {
  content: "";
  position: absolute;
  top: 0.15rem;
  bottom: 0.15rem;
  left: 0.15rem;
  right: 0.15rem;
  border-radius: 0.75rem;
  border: 2px solid red;
  pointer-events: none;
}
section.container .content .button:hover {
  transform: scale(calc(var(--btn-scl, 1) * 1.1));
}
section.container .content .button:active {
  transform: scale(calc(var(--btn-scl, 1) * 0.95));
}
section.container .content .button.big {
  --btn-scl: 2;
}
.text {
  color: #ed2d21;
  font-weight: 700;
  max-width: 80vw;
}
.text.big {
  font-size: 2rem;
  text-align: center;
}
.text.bigger {
  font-size: 4rem;
  text-align: center;
}
.text.huge {
  font-size: 10rem;
  text-align: center;
  filter: drop-shadow(2px 2px 1px white) drop-shadow(-2px 2px 1px white) drop-shadow(-2px -2px 1px white) drop-shadow(2px -2px 1px white) drop-shadow(-2px 2px 1px white) drop-shadow(-2px 2px 1px white) drop-shadow(2px 2px 1px white);
}
.big_ingredient {
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.big_ingredient img {
  height: 150px;
}
.jiggle {
  transition: transform 0.15s;
  animation: jiggle 0.15s alternate-reverse infinite ease-in-out;
}
@keyframes jiggle {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}
.pulse {
  animation: pulse 1.5s alternate-reverse infinite ease-in-out;
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.4);
  }
}
@keyframes inflate {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.inflate {
  animation: inflate 1s;
}
.deflate {
  animation: inflate 0.5s reverse;
  transform: scale(0);
}
