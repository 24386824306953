.image_input {
  aspect-ratio: 1/1;
  position: relative;
  color: #d4d4d4;
}
.image_input:hover {
  background-color: #f5f5f5;
  color: #737373;
}
.image_input:hover > .corner {
  border-color: #737373;
}
.image_input.square {
  aspect-ratio: 1/1;
}
.image_input.horizontal {
  aspect-ratio: 16/9;
}
.image_input.vertical {
  aspect-ratio: 9/16;
}
.image_input > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.75rem;
}
.image_input > img.contain {
  object-fit: contain;
}
.image_input > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.75rem;
  line-height: 1;
}
.image_input > .corner {
  position: absolute;
  z-index: 0;
  aspect-ratio: 1/1;
  border-color: #d4d4d4;
  width: 16.66666667%;
}
.image_input > .corner.top {
  border-top-width: 3px;
  top: 1rem;
}
.image_input > .corner.top.right {
  border-top-right-radius: 1rem;
}
.image_input > .corner.top.left {
  border-top-left-radius: 1rem;
}
.image_input > .corner.bottom {
  border-bottom-width: 3px;
  bottom: 1rem;
}
.image_input > .corner.bottom.right {
  border-bottom-right-radius: 1rem;
}
.image_input > .corner.bottom.left {
  border-bottom-left-radius: 1rem;
}
.image_input > .corner.right {
  border-right-width: 3px;
  right: 1rem;
}
.image_input > .corner.left {
  border-left-width: 3px;
  left: 1rem;
}
.image_input > input {
  position: absolute;
  inset: 0 0 0 0;
  opacity: 0;
  cursor: pointer;
  z-index: 20;
}
